import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { trackFilterVendors } from '../../pages/VendorsSearch/containers/FilterPills/helpers';

export interface UseTrackFilterVendorsOptions {
	categoryCode: Category.CategoryCode;
	categoryName: string;
	location: Redux.Location;
}

interface TrackVendors {
	(
		categoryFilters: Filters.AppliedFilters,
		trackAction: Search.TrackAction,
	): void;
}

type UseTrackFilterVendorsReturn = [TrackVendors];

interface UseTrackFilterVendors {
	(options: UseTrackFilterVendorsOptions): UseTrackFilterVendorsReturn;
}

const useTrackFilterVendors: UseTrackFilterVendors = (options) => {
	const { categoryCode, categoryName, location } = options;
	const { track } = useAnalyticsContext();

	const trackVendors: TrackVendors = (categoryFilters, trackAction) => {
		trackFilterVendors({
			track,
			categoryCode,
			categoryFilters,
			categoryName,
			location,
			trackAction,
		});
	};

	return [trackVendors];
};

export default useTrackFilterVendors;
