import NavigableModal from '@components/NavigableModal/NavigableModal';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import LocationTypeahead from '@xo-union/tk-component-location-typeahead';
import { ModalHeader } from '@xo-union/tk-component-modals';
import React, { useState } from 'react';
import { updateLocation } from '../../../../redux/location';
import { setCookieLocation } from '../../../../utils/cookie/location';
import {
	CATEGORY_LANDING_PAGE,
	CHANGE_LOCATION,
} from '../../../../utils/tracking';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import Styles from './styles.scss';

export interface ChangeLocationModalProps {
	headerText?: string;
	closeCallback: () => void;
	hash: string;
	isModalOpen: boolean;
}

export const ChangeLocationModal = ({
	closeCallback,
	headerText,
	hash,
	isModalOpen,
}: ChangeLocationModalProps) => {
	const [locationHash, setLocationHash] = useState<Union.Location | null>(null);
	const { track } = useAnalyticsContext();
	const dispatch = useAppDispatch();
	const location = useAppSelector((state) => state.location);

	const header = headerText || 'My Wedding Location';

	const checkUserInput = () => {
		if (locationHash !== null) {
			setLocationHash(null);
		}
	};

	const trackLocationChange = () => {
		track({
			event: CHANGE_LOCATION,
			properties: {
				sourcePage: CATEGORY_LANDING_PAGE,
			},
		});
	};

	const updateSearch = async () => {
		dispatch(updateLocation(locationHash, location.ip));
		setCookieLocation(locationHash);
		trackLocationChange();
		return closeCallback();
	};

	const handleLocationSelection = (locationHash: Union.Location | null) => {
		setLocationHash(locationHash);
	};

	return (
		<NavigableModal
			size="md"
			className={Styles.modal}
			hash={hash}
			isModalOpen={isModalOpen}
			closeCallback={closeCallback}
		>
			<ModalHeader>{header}</ModalHeader>
			<div className={Styles.modalBody}>
				<div className={Styles.fieldWrapper}>
					<LocationTypeahead
						onChange={handleLocationSelection}
						onInput={checkUserInput}
						label="City, State"
					/>
				</div>
				<div className={Styles.buttonContainer}>
					<Button
						size="md"
						disabled={locationHash === null}
						onClick={updateSearch}
					>
						Search
					</Button>
				</div>
			</div>
		</NavigableModal>
	);
};
