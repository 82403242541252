import { buildSearchRef } from '@components/shared/Search/helpers';
import { useAppSelector } from '@redux/hooks';
import { Categories, Location } from '@typings/redux';
import { useCallback } from 'react';

export const useSearchOnDropdownSelection = (
	setDropDownOpen: (status: boolean) => void,
) => {
	const location = useAppSelector((state) => state.location);

	const onDropdownSelection = useCallback(
		(catCode: string, categories: Categories) => {
			setDropDownOpen(false);
			if (location?.city && location?.stateCode && catCode !== 'REC') {
				const locationHash = {
					city: location.city,
					stateCode: location.stateCode,
				};
				const href = buildSearchRef(categories, catCode, locationHash);
				if (href) {
					window.location.href = href;
				}
			}
		},
		[location, setDropDownOpen],
	);

	return { onDropdownSelection };
};
