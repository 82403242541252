import { useMemo } from 'react';
import { isInSpotlightMarket } from '../../pages/VendorsSearch/utils';

interface UseSpotlightExperimentParams {
	categoryCode: Redux.Category['code'];
	marketCode: string;
}

const useMoreSpotlights = ({
	categoryCode,
	marketCode,
}: UseSpotlightExperimentParams) => {
	const isUsingMoreSpotlights = useMemo(
		() => isInSpotlightMarket(marketCode, categoryCode),
		[categoryCode, marketCode],
	);

	return [isUsingMoreSpotlights];
};

export { useMoreSpotlights };
