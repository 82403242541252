import { searchWithBoundingBoxQuery } from '@api/graphql/searchWithBoundingBox';
import axios from '@axios';
import Honeybadger from '@honeybadger-io/js';
import { useAppSelector } from '@redux/hooks';
import type { SearchResponse } from '@typings/api';
import { AppliedFilters } from '@typings/filters';
import type { BoundingBox, SortType } from '@typings/search';
import { FilterDetails } from '@typings/search';
import { generateSeed } from '@utils/generateSeed';
import sortParam from '@utils/sortParam';
import queryString from 'query-string';
import { MAX_VENDORS } from '../pages/VendorsSearch/containers/MapView/constants';
import type { Bounds } from '../pages/VendorsSearch/containers/MapView/utils/usStateBounds';
import {
	getAllFilterIds,
	getLocationFromSlug,
	getValidFilters,
	memoizedSearchFiltersToMap,
	splitMultipleFilters,
} from '../pages/VendorsSearch/utils';

export type UserLocation = {
	city: string;
	stateCode: string;
};
export type Match = {
	params: {
		facet: string;
		locationSlug: string;
	};
};
type SetFilteredVendors = (vendors: Vendor.Raw[]) => void;
type TranslateBoundingBox = (bbox: Bounds) => BoundingBox;
type SearchByBoundingBox = (
	bbox: Bounds,
	setFilteredVendors: SetFilteredVendors,
) => void;

type SearchVariables = {
	boundingBox: BoundingBox;
	categoryGuid?: string;
	city?: string;
	filters?: string[];
	page?: number;
	sort?: SortType;
	state?: string;
};

const getSearchResults = async (
	setFilteredVendor: SetFilteredVendors,
	searchVariables: SearchVariables,
) => {
	const seed = generateSeed();
	const data = {
		query: searchWithBoundingBoxQuery(),
		variables: {
			...searchVariables,
			seed,
		},
	};

	try {
		const searchResponse = await axios.post<SearchResponse>(data);
		if (searchResponse?.data?.data?.search?.profiles) {
			const maxAllowedVendors = searchResponse.data.data.search.profiles.slice(
				0,
				MAX_VENDORS,
			);
			setFilteredVendor(maxAllowedVendors);
		}
	} catch (error) {
		Honeybadger.notify(error, 'Error searching with bounding box');
	}
};

const buildFilters = (
	appliedFilters: AppliedFilters,
	searchLocation: string,
	facet: string,
	searchFilters: FilterDetails[],
) => {
	const { sort, gatekeeper, ...restFilters } =
		queryString.parse(searchLocation);
	const filterArr = splitMultipleFilters(restFilters);

	if (facet) {
		return {
			filters: getAllFilterIds(appliedFilters),
			sort,
		};
	}

	const filters = getValidFilters(
		filterArr,
		memoizedSearchFiltersToMap(searchFilters),
	);

	return {
		filters,
		sort,
	};
};

const translateBoundingBox: TranslateBoundingBox = (bbox) => {
	return {
		topLeft: {
			latitude: bbox.north,
			longitude: bbox.west,
		},
		bottomRight: {
			latitude: bbox.south,
			longitude: bbox.east,
		},
	};
};

export const useSearchByBoundingBox = (
	match: Match,
	searchLocation: string,
	isCleared: boolean,
) => {
	const { facet } = match.params;
	const userLocation = getLocationFromSlug(match.params.locationSlug);
	const appliedFilters = useAppSelector(
		(state) => state.filters.appliedFilters,
	);
	const category = useAppSelector((state) => state.category);
	const searchFilters = useAppSelector((state) => state.search.filters.filters);
	const page = useAppSelector((state) => state.search.pagination.page);
	const zeroIndexBasedPage = page > 0 ? page - 1 : 0;

	const { filters, sort } = buildFilters(
		appliedFilters,
		searchLocation,
		facet,
		searchFilters,
	);

	const searchOptions: Partial<SearchVariables> = {
		categoryGuid: category.id || '',
		city: userLocation.city || '',
		filters: isCleared ? [category.id] : [category.id, ...filters],
		page: zeroIndexBasedPage,
		sort: sortParam(sort),
		state: userLocation.stateCode || '',
	};

	const searchByBoundingBox: SearchByBoundingBox = (
		bbox,
		setFilteredVendors,
	) => {
		const boundingBox = translateBoundingBox(bbox);
		getSearchResults(setFilteredVendors, { ...searchOptions, boundingBox });
	};

	return { searchByBoundingBox };
};
