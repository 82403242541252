import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { newStartingPriceAssignmentSelector } from '@redux/experiments/selectors/new-starting-price';
import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';

export const useIsPricingEnabled = () => {
	const featureFlags = useFeatureFlags();
	const isInNewStartingPrice =
		useAppSelector(newStartingPriceAssignmentSelector) === 'new-starting-price';

	return useMemo(
		() => featureFlags[FEATURE_FLAGS.LISTINGS_PRICING] && isInNewStartingPrice,
		[featureFlags[FEATURE_FLAGS.LISTINGS_PRICING]],
	);
};
