import { useAppSelector } from '@redux/hooks';
import { setGoogleMapsBounds } from '../pages/VendorsSearch/containers/MapView/utils';
import { usStateBounds } from '../pages/VendorsSearch/containers/MapView/utils/usStateBounds';

export const useDefaultStateBounds = () => {
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const bounds = useAppSelector((state) => state.search.boundingBox);
	const defaultBounds =
		stateCode && usStateBounds[stateCode]
			? usStateBounds[stateCode]
			: setGoogleMapsBounds(bounds);

	return { defaultBounds };
};
