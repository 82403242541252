import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { useEffect } from 'react';

export const useListingsFeatures = () => {
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (featureFlags[FEATURE_FLAGS.LISTINGS_PRICING]) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment tracking
			console.log('Pricing on Listings Feature Flag enabled');
		}
	}, [featureFlags[FEATURE_FLAGS.LISTINGS_PRICING]]);
};
