import React, { useCallback, useEffect, useState } from 'react';

/**
 * Used to detect if an element is sticky via a ref. As this relies on intersection ratio to be less than one, top/bottom/etc has to be a negative value such as '-1px'
 * @param ref
 */
const useIsSticky = (
	ref: React.MutableRefObject<HTMLDivElement | null>,
	isMobile: boolean,
) => {
	const [isSticky, setIsSticky] = useState(false);

	const isStickyCallback = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			setIsSticky(entry.intersectionRatio < 1);
		},
		[],
	);

	const options: IntersectionObserverInit = {
		root: null,
		rootMargin: '0px',
		threshold: 1,
	};

	useEffect(() => {
		setIsSticky(isMobile);
	}, [isMobile]);

	useEffect(() => {
		const observer = new IntersectionObserver(isStickyCallback, options);

		if (ref?.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref?.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [isStickyCallback, ref]);

	return isSticky;
};

export default useIsSticky;
