import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';

export const useTrackOpenFilterMenu = () => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(sourceContent: string) => {
			track({
				event: 'Vendor Filter Configured',
				properties: {
					sourceContent: sourceContent
						.toLowerCase()
						.trim()
						.replace(/\s+/g, '_'),
					sourcePage: 'category results',
					action: 'open_filter_menu',
				},
			});
		},
		[track],
	);
};
